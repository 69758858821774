/* fix not valid css */
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: #b0b3b4;
}

.DateInput_input__regular {
  font-weight: 400;
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: #b0b3b4;
}

/* custom overwrites */
.DayPicker {
  font-family: 'Roboto';
}
.CalendarDay__default {
  border-color: transparent;
}
.DayPickerNavigation_container__vertical {
  box-shadow: none;
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover,
.DayPickerNavigation_button__default {
  border: 0;
}
