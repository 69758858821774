@keyframes fadein {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
