.carousel .slide {
  background: none;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: visible;
}
.carousel .control-prev.control-arrow {
  left: -60px;
}
.carousel .control-next.control-arrow {
  right: -60px;
}
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #eee;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  background: rgba(0, 0, 0, 0.2);
}
